import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo-muc.svg";
import useInput from "../hooks/use-input";
import { useQuery } from "../hooks";
import api from "../services/api";
import { Link } from "react-router-dom";
import Footer from "./Footer";

export default function GetMissionary(props) {
  let query = useQuery();
  let navigate = useNavigate();
  //check if the missionary exists, if not re-direct to GetPersonalData

  const {
    value: enteredCedula,
    setValue: setEnteredCedula,
    isValid: enteredCedulaIsValid,
    hasError: cedulaInputHasError,
    valueChangedHandler: cedulaChangedHandler,
    inputBlurHandler: cedulaBlurHandler,
  } = useInput((value) => value.trim() !== ""); //check if is empty

  const {
    value: enteredBornDate,
    isValid: enteredBornDateIsValid,
    hasError: bornDateHasError,
    valueChangedHandler: bornDateChangedHandler,
    inputBlurHandler: bornDateBlurHandler,
  } = useInput((value) => value.trim() !== "");

  let inputIsValid = false;
  if (enteredCedulaIsValid && enteredBornDateIsValid) {
    inputIsValid = true;
  }

  const handleSubmission = (e) => {
    e.preventDefault();
    navigate(`/check-entry?issuedId=${enteredCedula}`);
  };

  const onCedulaChange = (e) => {
    if (e.target.value.length > 0)
      setEnteredCedula(e.target.value.match(/[A-Za-z0-9]+/g).join(""));
    else setEnteredCedula(e.target.value);
  };

  return (
    <div className="grid place-items-center h-screen sm:rounded-lg body-inscription">
      <Link to="/">
        <img src={logo} alt="logo" className="absolute logo-in-cedula" />
      </Link>

      <div className="px-4 py-5 sm:p-6">
        <h1 className="font-extrabold text-gray-700 title-cedula">
          Verifica tu inscripción
        </h1>
        <div className="py-10 px-10 bg-white card-cedula">
          <div>
            <form onSubmit={handleSubmission}>
              <div className="max-w-xl text-sm text-black-500">
                <label htmlFor="cedula" className="">
                  Ingresá tu número de cédula
                </label>
                <input
                  type="text"
                  name="cedula"
                  id="cedula"
                  placeholder="1234567"
                  autoComplete="off"
                  className="mt-2 pl-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md input-cedula "
                  value={enteredCedula}
                  onChange={onCedulaChange}
                  onBlur={cedulaBlurHandler}
                />
                {cedulaInputHasError && (
                  <p className="error-text">Debes ingresar tu cédula.</p>
                )}
              </div>

              <br />

              {/* <div className="max-w-xl text-sm text-black-500">
                                <label htmlFor="birthDate" className="">Ingresá tu fecha de nacimiento</label>
                                <input
                                    type="date"
                                    name="birthDate"
                                    id="birthDate"
                                    className="mt-2 pl-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md input-cedula "
                                    placeholder="dd/mm/aaaa"
                                    value={enteredBornDate}
                                    onChange={bornDateChangedHandler}
                                    onBlur={bornDateBlurHandler}
                                />
                            </div>
                            {bornDateHasError && <p className="error-text">Debe de ingresar su cedula.</p>}
              */}

              {/* <br /> */}

              <button
                type="submit"
                className="w-full font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
                onClick={() => {
                  if (inputIsValid) {
                    navigate(`/check-entry?govId=${enteredCedula}`);
                  }
                }}
              >
                Verificar
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
