import { useState, useEffect } from "react";
import logo from "../images/logo-muc.svg";
import api from "../services/api";
import { useQuery } from "../hooks";
import { useNavigate, Link } from "react-router-dom";
import Loader from "./Loader";
import Footer from "./Footer";

export default function GetMyInscription() {
  let query = useQuery();

  const [entry, setEntry] = useState(null);
  const [exists, setExists] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mission, setMission] = useState(null);
  const [meeting, setMeeting] = useState("");

  const handleSubmission = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    async function fetchData() {
      const _mission = await api.missions.getOne("active");
      setMission(_mission);
      await api.entries
        .check(query.get("issuedId"))
        .then((data) => {
          setEntry(data);
          setExists(true);
          setLoading(false);
        })
        .catch((e) => {
          setExists(false);
          setLoading(false);
        });
    }
    fetchData();
  }, [query]);

  function parseDateStr(dateStr) {
    if (dateStr.includes("T")) {
      const date = new Date(dateStr);
      return date.toLocaleDateString("es-py", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      const parts = dateStr.split("-");
      return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }
  }

  return (
    <div className="grid place-items-center h-screen sm:rounded-lg body-inscription">
      <Link to="/">
        <img src={logo} alt="logo" className="absolute logo-in-cedula" />
      </Link>
      <div className="px-4 py-5 sm:p-6 phone-pad-c">
        <div className="py-10 px-10 bg-white text-center card-cedula">
          <div>
            <div className="mb-2 max-w-xl text-sm text-gray-700">
              <p>Mi inscripción</p>
            </div>

            {loading && <Loader />}
            {!loading && !exists && (
              <p>
                No se encontraron datos de inscripción para el documento
                ingresado.
              </p>
            )}
            {!loading && exists && (
              <>
                <h1 className="font-bold text-gray-700 title-cedula">
                  {entry?.firstName}
                </h1>
                <br />
                <div className="text-left mb-2 max-w-xl text-sm text-gray-500">
                  <p>Nro. de Documento</p>
                  <p className="text-black">{entry?.issuedId}</p>
                  <br />
                  <p>Fecha y hora de inscripción</p>
                  <p className="text-black">
                    {entry?.entryDate && parseDateStr(entry?.entryDate)}
                  </p>

                  <br />

                  <p>Encuentro previo</p>
                  <p className="text-black">
                    {`${entry?.meetingGoing.title}` +
                      " " +
                      parseDateStr(entry?.meetingGoing.start)}
                  </p>

                  <br />

                  <p>Lista de espera</p>
                  {entry?.isInWaitingList ? (
                    <p className="text-red-700">Estás lista de espera</p>
                  ) : (
                    <p className="text-green-700">
                      No estás en lista de espera
                    </p>
                  )}
                  <br />

                  {/*<p>Vacunación COVID-19</p>*/}
                  {/*entry.isVaccinated && <p className="text-green-700">SI</p>*/}
                  {/*!entry.isVaccinated && (
                    <p className="text-red-700 font-semibold iph-se-lout">
                      Estás a un paso de finalizar tu inscripción. No logramos
                      encontrar tu registro de vacunación, favor consultar en{" "}
                      <a
                        href="https://datos.mspbs.gov.py/vacunados/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://datos.mspbs.gov.py/vacunados/
                      </a>{" "}
                      o mandanos una foto de tu carnet de vacunación a{" "}
                      <a href="mailto:inscripciones@muc.org.py">
                        inscripciones@muc.org.py
                      </a>
                      .
                    </p>
                  )*/}
                </div>{" "}
              </>
            )}
          </div>
        </div>
        <Link to="/">
          <button className="mt-5 mb-4 w-full font-medium btn-my-inscription iph-se-lout">
            <strong>Volver al inicio</strong>
          </button>
        </Link>
      </div>
      <Footer />
    </div>
  );
}
