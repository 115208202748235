import React from "react";

const Footer = () => {
  return (
    <footer
      style={{
        textAlign: "center",
      }}
    >
      <p>
        &copy; MUC 2025 | Una colaboración de{" "}
        <a
          style={{
            fontWeight: "700",
            color: "#be1622",
            textDecoration: "underline",
          }}
          href="https://girolabs.com/es/inicio/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Girolabs
        </a>
      </p>
    </footer>
  );
};

export default Footer;
